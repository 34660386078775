



































































































































































































import axios from 'axios';
import { Component, Vue, Watch } from 'vue-property-decorator';
import ButtonCard from '@/components/ButtonCard.vue';
import { IConfig, IQueryConfig } from '@/models/IConfig';
import { IInstanceData } from '@/models/IInstanceData';
import { debounceFn } from 'debounce-decorator-ts';

@Component({ components: { ButtonCard } })
export default class Configuration extends Vue {
  public instaceData: IInstanceData = {
    api_token: '',
    color: '',
    colored_logo: '',
    name: '',
    site: '',
    slug: '',
    status: '',
  };

  public isDisabled: boolean = true;
  public alert: boolean = false;
  public showImportExport: boolean = false;

  public config: IConfig = {
    apiKey: '',
    activeLogo: false,
    logo: '',
    activeTitle: false,
    title: 'Pesquisa de Satisfação',
    buttonColor: '#Ef7b2e',
    backgroundColor: '#fefefe',
    preSurveyButtons: [{ text: 'SoluCX', link: 'https://solucx.com.br/' }],
  };

  public configByJSON: string = '';
  public configByURL: string = '';

  public get disabled(): boolean {
    return this.config.apiKey !== '' && this.config.preSurveyButtons.length > 0 ? false : true;
  }

  @Watch('config', { immediate: true, deep: true })
  public onConfigChange(): void {
    this.updateExportFieldJSON();
    this.updateExportFieldURL();
  }

  public mounted(): void {
    this.getDataFromLocalStorage();
  }

  public updateExportFieldJSON(): void {
    this.configByJSON = JSON.stringify(this.config);
    this.updateExportFieldURL();
  }

  @debounceFn(2000)
  public updateConfigByJSON(): void {
    try {
      const config: IConfig = JSON.parse(this.configByJSON);

      const hasApiKey: boolean = !!config.apiKey;
      const hasButtonColor: boolean = !!config.buttonColor;
      const hasActiveLogo: boolean = !!config.activeLogo;
      const hasLogo: boolean = !!config.logo;
      const hasActiveTitle: boolean = !!config.activeTitle;
      const hasTitle: boolean = !!config.title;
      const hasBackgroundColor: boolean = !!config.backgroundColor;
      const hasButtons: boolean = !!config.preSurveyButtons;

      const isValidConfig: boolean =
        hasApiKey &&
        hasButtonColor &&
        hasActiveLogo &&
        hasLogo &&
        hasActiveTitle &&
        hasTitle &&
        hasBackgroundColor &&
        hasButtons;

      if (isValidConfig) this.config = JSON.parse(this.configByJSON);
      else this.updateExportFieldJSON();
    } catch (error) {
      this.updateExportFieldJSON();
    }
  }

  public updateExportFieldURL(): void {
    const config: IQueryConfig = {
      apiKey: this.config.apiKey,
      activeLogo: this.config.activeLogo.toString(),
      logo: this.config.logo,
      activeTitle: this.config.activeTitle.toString(),
      title: this.config.title,
      buttonColor: encodeURIComponent(this.config.buttonColor),
      backgroundColor: encodeURIComponent(this.config.backgroundColor),
      preSurveyButtons: encodeURIComponent(JSON.stringify(this.config.preSurveyButtons)),
    };

    let host: string = window.location.hostname;
    if (host === 'localhost') host = `${window.location.hostname}:8080`;

    this.configByURL = host + this.serializeQuery(config);
  }

  public serializeQuery(obj: IQueryConfig): string {
    let queryString: string = '?';
    for (const key of Object.keys(obj)) {
      // @ts-ignore
      queryString += `${key}=${obj[key]}&`;
    }

    return queryString;
  }

  public saveData(): void {
    localStorage.setItem('apiKey', this.config.apiKey);
    localStorage.setItem('preSurveyButtons', JSON.stringify(this.config.preSurveyButtons));
    localStorage.setItem('activeLogo', JSON.stringify(this.config.activeLogo));
    localStorage.setItem('logo', this.config.logo);
    localStorage.setItem('activeTitle', JSON.stringify(this.config.activeTitle));
    localStorage.setItem('title', this.config.title);
    localStorage.setItem('buttonColor', this.config.buttonColor);
    localStorage.setItem('backgroundColor', this.config.backgroundColor);

    this.$router.push({ name: 'home' });
  }

  public getDataFromLocalStorage(): void {
    if (this.$route.query.clear) localStorage.clear();
    else {
      const apiKeyStorage: string | null = localStorage.getItem('apiKey');
      if (apiKeyStorage !== null) this.config.apiKey = apiKeyStorage;

      const buttonColorStorage: string | null = localStorage.getItem('buttonColor');
      if (buttonColorStorage !== null) this.config.buttonColor = buttonColorStorage;

      const activeLogoStorage: string | null = localStorage.getItem('activeLogo');
      if (activeLogoStorage !== null) this.config.activeLogo = JSON.parse(activeLogoStorage);

      const logoStorage: string | null = localStorage.getItem('logo');
      if (logoStorage !== null) this.config.logo = logoStorage;

      const activeTitleStorage: string | null = localStorage.getItem('activeTitle');
      if (activeTitleStorage !== null) this.config.activeTitle = JSON.parse(activeTitleStorage);

      const titleStorage: string | null = localStorage.getItem('title');
      if (titleStorage !== null) this.config.title = titleStorage;

      const backgroundColorStorage: string | null = localStorage.getItem('backgroundColor');
      if (backgroundColorStorage !== null) this.config.backgroundColor = backgroundColorStorage;

      const buttonsStorage: string | null = localStorage.getItem('preSurveyButtons');
      if (buttonsStorage !== null) this.config.preSurveyButtons = JSON.parse(buttonsStorage);
    }
  }

  public async getInstanceData(): Promise<void> {
    if (this.config.apiKey.length > 0) {
      try {
        const baseURL = 'https://pre-survey.solucx.com.br/api';
        const response = await axios.get(`${baseURL}?apiKey=${this.config.apiKey}`);
        this.instaceData = response.data;
        this.config.buttonColor = this.instaceData.color;
        this.config.logo = this.instaceData.colored_logo;
      } catch (error) {
        this.showAlert();
        this.config.apiKey = '';
      }
    }
  }

  public addButton(): void {
    this.config.preSurveyButtons.push({ text: '', link: '' });
  }

  public deleteButton(index: number): void {
    this.config.preSurveyButtons.splice(index, 1);
  }

  public showAlert(): void {
    this.alert = true;
    setTimeout(() => {
      this.alert = false;
    }, 3000);
  }
}
