





















import axios from 'axios';
import Loading from '@/components/Loading.vue';
import { Component, Vue } from 'vue-property-decorator';
import { IButton } from '@/models/IButton';
import { IInstanceData } from '@/models/IInstanceData';

@Component({ components: { Loading } })
export default class Home extends Vue {
  public instaceData: IInstanceData = {
    api_token: '',
    color: '',
    colored_logo: '',
    name: '',
    site: '',
    slug: '',
    status: '',
  };
  public btnColor: string = '';
  public isLoading: boolean = true;

  public get apiKey(): string {
    const apiKeyQuery = this.$route.query.apiKey;
    const apiKeyStorage: string | null = localStorage.getItem('apiKey');

    if (apiKeyQuery) return apiKeyQuery as string;
    if (apiKeyStorage) return apiKeyStorage as string;
    return '';
  }

  public get activeLogo(): boolean {
    const activeLogoQuery = this.$route.query.activeLogo;
    const activeLogoStorage = localStorage.getItem('activeLogo');
    if (activeLogoQuery) return JSON.parse(activeLogoQuery as string);
    if (activeLogoStorage) return JSON.parse(activeLogoStorage as string);
    return false;
  }

  public get logo(): string {
    const logoQuery = this.$route.query.logo;
    const logoStorage = localStorage.getItem('logo');
    if (logoQuery) return logoQuery as string;
    if (logoStorage) return logoStorage as string;
    return '';
  }

  public get activeTitle(): boolean {
    const activeTitleQuery = this.$route.query.activeTitle;
    const activeTitleStorage = localStorage.getItem('activeTitle');
    if (activeTitleQuery) return JSON.parse(activeTitleQuery as string);
    if (activeTitleStorage) return JSON.parse(activeTitleStorage as string);
    return false;
  }

  public get title(): string {
    const titleQuery = this.$route.query.title;
    const titleStorage = localStorage.getItem('title');
    if (titleQuery) return titleQuery as string;
    if (titleStorage) return titleStorage as string;
    return '';
  }

  public get buttonColor(): string {
    const buttonColorQuery = this.$route.query.buttonColor;
    const buttonColorStorage = localStorage.getItem('buttonColor');
    if (buttonColorQuery) return buttonColorQuery as string;
    if (buttonColorStorage) return buttonColorStorage as string;
    return this.instaceData.color;
  }

  public get backgroundColor(): string {
    const backgroundColorQuery = this.$route.query.backgroundColor;
    const backgroundColorStorage = localStorage.getItem('backgroundColor');

    if (backgroundColorQuery) return backgroundColorQuery as string;
    if (backgroundColorStorage) return backgroundColorStorage as string;
    return '#fefefe';
  }

  public get buttonList(): IButton[] {
    const buttonListQuery = this.$route.query.preSurveyButtons;
    const buttonListStorage = localStorage.getItem('preSurveyButtons');

    if (buttonListQuery) return this.convertQueryStringObject(buttonListQuery as string);
    if (buttonListStorage) return this.convertQueryStringObject(buttonListStorage as string);
    return [];
  }

  public async mounted(): Promise<void> {
    if (this.apiKey) {
      try {
        const baseURL = 'https://pre-survey.solucx.com.br/api';
        const response = await axios.get(`${baseURL}?apiKey=${this.apiKey}`);
        this.instaceData = response.data;
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
    }

    this.setBackgroundColor();
    this.setLocalStorageData();
    if (this.instaceData.api_token === '' || this.buttonList.length === 0) this.$router.push({ name: 'config' });
    this.isLoading = false;
  }

  public setBackgroundColor(): void {
    const body = document.querySelector('body');
    if (body && this.backgroundColor) body.style.backgroundColor = this.backgroundColor;
  }

  public convertQueryStringObject(param: string): IButton[] {
    const object = JSON.parse(param);
    const array: IButton[] = [];
    for (const key of Object.keys(object)) {
      array.push(object[key]);
    }

    return array;
  }

  public setLocalStorageData(): void {
    localStorage.setItem('apiKey', this.apiKey);
    localStorage.setItem('preSurveyButtons', JSON.stringify(this.buttonList));
    localStorage.setItem('buttonColor', this.buttonColor);
    localStorage.setItem('backgroundColor', this.backgroundColor);
  }
}
