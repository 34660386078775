

















import { IButton } from '@/models/IButton';
import { Component, PropSync, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ButtonCard extends Vue {
  @PropSync('data') public syncedData!: IButton;
  @Prop() public index!: number;
}
