import { render, staticRenderFns } from "./ButtonCard.vue?vue&type=template&id=9ff1168c&scoped=true&"
import script from "./ButtonCard.vue?vue&type=script&lang=ts&"
export * from "./ButtonCard.vue?vue&type=script&lang=ts&"
import style0 from "./ButtonCard.vue?vue&type=style&index=0&id=9ff1168c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9ff1168c",
  null
  
)

export default component.exports