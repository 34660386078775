import Vue from 'vue';
import App from './App.vue';
import router from './router/index';
import { BootstrapVue } from 'bootstrap-vue';
Vue.config.productionTip = false;

import '@/assets/scss/custom-theme.scss';

Vue.use(BootstrapVue);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
